import * as React from "react";
import {useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import {Link} from "gatsby";
import {Client, handleApiError} from "../../services/ApiService";

const IndexPage = () => {
    const [impressum, setImpressum] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setImpressum(res.data[0].Impressum)
        }).catch(handleApiError)
    })


    return (
        <Layout pageTitle="Impressum">
            <div className={"container py-5"}>
                <h2>Impressum</h2>
                <div dangerouslySetInnerHTML={{__html: impressum}}/>
                <Link to={'/agb'}>Allgemeine Geschäftsbedingungen </Link>
            </div>
        </Layout>
    )
}

export default IndexPage;
